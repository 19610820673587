import { createWithEqualityFn } from 'zustand/traditional';

import { ApplicationType, ApplicationStatus, ApplyingCollegeWithSchool } from '@youscience/college-service-common';

import { INITIAL_PAGE_FILTERS } from '@constants/applyingSchoolsPlanner';
import { ORDER_BY_EARLIEST } from '@constants';

import { baseService } from '@services/base.service';
import { useNotificationStore } from './notificationStore';

interface ApplyingSchoolsFilters {
  applicationType: ApplicationType | string;
  applicationStatus: ApplicationStatus | string;
}

interface ApplyingSchoolsPlannerStore {
  appliedSchools: ApplyingCollegeWithSchool[];
  pageFilters: ApplyingSchoolsFilters;
  orderBy: string;

  getAppliedSchool: () => Promise<void>;
  deleteAppliedSchool: (schoolId: number) => Promise<void>;
  editAppliedSchool: (data: Partial<ApplyingCollegeWithSchool>) => void;

  setPageFilters: (filters: Partial<ApplyingSchoolsFilters>) => void;
  setOrderBy: (order: string) => void;
}

const initialState = {
  appliedSchools: [],
  pageFilters: INITIAL_PAGE_FILTERS,
  orderBy: ORDER_BY_EARLIEST,
};

export const useApplyingSchoolsPlannerStore = createWithEqualityFn<ApplyingSchoolsPlannerStore>()(
  (set, get) => ({
    ...initialState,

    getAppliedSchool: async () => {
      const appliedSchools = await baseService.getAsync<ApplyingCollegeWithSchool[]>('/user/applying-college');

      set({
        appliedSchools,
      });
    },

    editAppliedSchool: async ({
      application,
      priority,
      decision,
      admissionStatus,
      id,
      defaultChecklist,
      customChecklist,
    }: Partial<ApplyingCollegeWithSchool>) => {
      const { appliedSchools } = get();

      const updatedSchools = appliedSchools.map((school) => {
        if (school.id === id) {
          return {
            ...school,
            admissionStatus,
            priority,
            decision,
            application,
            defaultChecklist,
            customChecklist,
          };
        }
        return school;
      });

      set({
        appliedSchools: updatedSchools as ApplyingCollegeWithSchool[],
      });

      const updatedApplicationData = {};

      Object.entries({ ...application }).forEach(([key, value]) => {
        if (value) (updatedApplicationData as Record<string, unknown>)[key] = value;
      });

      const updatedSchoolData = {
        defaultChecklist,
        customChecklist,
      };

      Object.entries({ admissionStatus, priority, decision }).forEach(([key, value]) => {
        if (value) (updatedSchoolData as Record<string, unknown>)[key] = value;
      });

      await baseService.patchAsync(`/user/applying-college/${id ?? ''}`, {
        ...updatedSchoolData,
        application: updatedApplicationData,
      });
    },

    deleteAppliedSchool: async (schoolId: number) => {
      const { appliedSchools } = get();

      await baseService.deleteAsync(`/user/applying-college/${schoolId}`);

      const filteredSchools = appliedSchools.filter((school) => school.id !== schoolId);

      set({
        appliedSchools: filteredSchools,
      });

      useNotificationStore.getState().notify({
        title: 'School was removed.',
        message: 'You can add it back to your list.',
        severity: 'success',
      });
    },

    setPageFilters: (filters: Partial<ApplyingSchoolsFilters>) => {
      set({
        pageFilters: { ...get().pageFilters, ...filters },
      });
    },

    setOrderBy: (orderBy: string) => {
      set({
        orderBy,
      });
    },
  }),
  Object.is,
);
