import { Api } from './api.service';

const discoveryUrl = `${import.meta.env.VITE_DISCOVERY_URL}/api/v1`;

export class DiscoveryService extends Api {
  constructor() {
    super({
      withCredentials: true,
      baseURL: discoveryUrl,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }
}

export const discoveryService = new DiscoveryService();
