import { AxiosResponse } from 'axios';
import { createWithEqualityFn } from 'zustand/traditional';
import { UserDocument } from '@youscience/user-service-common';
import {
  ApplicantDetails,
  RoleEnum,
  RolesDocument,
  RecommenderDocument,
  MembersDocument,
} from '@youscience/commonapp-service-common';

import { authService } from '@services/auth.api.service';
import { commonAppService } from '@services/commonApp.service';

import { useAuthStore } from '@store/authStore';
import { useUserPreferencesStore } from '@store/userPreferencesStore';
import { notify } from '@store/notificationStore';

import { RecommenderInfo, RecommenderInfoUpdateResponse } from '@interfaces/recommenderWorkspace';

interface CommonAppAdminStore {
  isLoading: boolean;
  applicantId: number | null;
  requestId: string | null;
  members: MembersDocument[];
  users: UserDocument[];
  applicantDetails: ApplicantDetails;
  roles: RolesDocument[];
  userRoles: RoleEnum[];
  connectedStudents: RecommenderDocument[];
  recommenderId: string | null;
  recommenderLastUpdated: number;
  getCollegeList: () => Promise<void>;
  getUsers: () => Promise<void>;
  getRoles: () => Promise<RolesDocument[]>;
  removeRole: (user: UserDocument, role: RoleEnum) => void;
  getConnectedStudents: () => Promise<void>;
  updateRecommenderInfo: (data: RecommenderInfo) => Promise<void>;
}

const initialState = {
  isLoading: false,
  applicantId: null,
  requestId: null,
  recommenderLastUpdated: Date.now(),
} as CommonAppAdminStore;

export const useCommonAppAdminStore = createWithEqualityFn<CommonAppAdminStore>()(
  (set, get) => ({
    ...initialState,

    getCollegeList: async () => {
      set({
        isLoading: true,
      });

      const members = await commonAppService.get<unknown, AxiosResponse<[MembersDocument]>>('/college-list');

      set({
        members: members.data,
        isLoading: false,
      });
    },

    getUsers: async () => {
      set({
        isLoading: true,
      });

      const tenantId = useAuthStore.getState().authSession?.currentAccess?.tenantId;

      const users = await authService.getUsers({
        roles: ['Admin', 'Staff', 'Proctor'],
        limit: 100,
        skip: 0,
        includeAccess: true,
        spreadAccess: true,
        tenantId: tenantId ? [tenantId] : undefined,
      });

      set({
        users,
        isLoading: false,
      });
    },

    removeRole: async (user: UserDocument, role: RoleEnum) => {
      set({
        isLoading: true,
      });

      await commonAppService.deleteAsync<{ userId: string; role: RoleEnum }>(`remove-role/${user.userId}/${role}`);

      void get().getRoles();

      set({
        isLoading: false,
      });
    },

    getRoles: async () => {
      set({
        isLoading: true,
      });

      const { userId } = useUserPreferencesStore.getState().userPreferences;

      const roles = await commonAppService.getAsync<RolesDocument[]>('roles');
      const rolesByUserId = roles?.find((role) => role.userId === userId)?.roles;

      set({
        isLoading: false,
        roles,
        userRoles: rolesByUserId,
      });

      return roles;
    },

    getConnectedStudents: async () => {
      set({
        isLoading: true,
      });

      const connectedStudents = await commonAppService.getAsync<[]>('connected-students');

      set({
        connectedStudents,
        isLoading: false,
      });
    },

    updateRecommenderInfo: async (data: RecommenderInfo) => {
      set({
        isLoading: true,
      });

      const response = await commonAppService.patchAsync<RecommenderInfoUpdateResponse, RecommenderInfo>('/role', data);

      set({
        isLoading: false,
        userRoles: response.roles,
      });

      await useAuthStore.getState().getMe();

      set({
        recommenderLastUpdated: Date.now(),
      });

      notify({
        message: 'Your profile has been updated',
        severity: 'success',
      });
    },
  }),
  Object.is,
);
