import { ProfileWithExtendedData, useAuthStore } from '@store/authStore';

export const getGraduationYear = (grade: number) => {
  if (grade === -1) {
    return 0;
  }

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const graduationYear = currentYear + (12 - grade);

  if (currentMonth > 5) {
    return graduationYear + 1;
  }

  return graduationYear;
};

export const isAlreadyGraduated = () => {
  const { userData } = useAuthStore((state) => state.authSession);
  const { gradeLevel, plan } = (userData?.profile ?? {}) as ProfileWithExtendedData;

  return !!(!gradeLevel && (plan?.now || plan?.future?.length));
};
