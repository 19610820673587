import { Api } from './api.service';

export class BaseService extends Api {
  constructor() {
    super({
      withCredentials: true,
      baseURL: `${import.meta.env.VITE_API_BASE_URL}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }
}

export const baseService = new BaseService();
