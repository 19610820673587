export const SCHOOLS_PATH = '/schools';
export const SCHOOLS_BY_MAJOR_PATH = '/schools-by-major';
export const MAJORS_PATH = '/majors';
export const EDUCATION_PLANNER_PATH = '/education-planner';
export const SCHOLARSHIPS_PATH = '/scholarships';
export const RECOMMENDER_SPACE_PATH = '/recommender-workspace';

export const getSchoolDetailsPath = (id: number | string = ':id') => `${SCHOOLS_PATH}/${id.toString()}`;

export const getSchoolsByMajorPath = (cipCode = ':cipCode') => `${SCHOOLS_BY_MAJOR_PATH}/${cipCode}`;

export const getMajorDetailsPath = (cipCode = ':cipCode') => `${MAJORS_PATH}/${cipCode}`;

export const getScholarshipDetailsPath = (id: number | string = ':id') => `${SCHOLARSHIPS_PATH}/${id}`;

export const getStudentDetailsPath = (id: number | string = ':id') => `${RECOMMENDER_SPACE_PATH}/student/${id}`;

export const ROUTES = {
  ROOT: '/',
  SCHOOLS: SCHOOLS_PATH,
  SCHOOL_DETAILS: getSchoolDetailsPath(),
  FAVORITE_SCHOOLS: `${SCHOOLS_PATH}/favorites`,
  MAJORS: MAJORS_PATH,
  MAJOR_DETAILS: getMajorDetailsPath(),
  FAVORITE_MAJORS: `${MAJORS_PATH}/favorites`,
  SCHOOLS_BY_MAJOR: getSchoolsByMajorPath(),
  EDUCATION_PLANNER: EDUCATION_PLANNER_PATH,
  EDUCATION_PLANNER_APPLICATIONS: `${EDUCATION_PLANNER_PATH}-applications`,
  SCHOLARSHIPS: SCHOLARSHIPS_PATH,
  SCHOLARSHIP_DETAILS: getScholarshipDetailsPath(),
  RECOMMENDER_SPACE: RECOMMENDER_SPACE_PATH,
  STUDENT_DETAILS: getStudentDetailsPath(),
  RECOMMENDER_PROFILE: `${RECOMMENDER_SPACE_PATH}/profile`,
  SCHOOL_PROFILE: `${RECOMMENDER_SPACE_PATH}/school-profile`,
};
