export const BREADCRUMBS_PAGE_TEXT = {
  ROOT: 'Explore and Plan',
  SCHOOLS: 'Schools',
  SCHOOL_DETAILS: 'School details',
  FAVORITE_SCHOOLS: 'Favorite schools',
  MAJORS: 'Majors',
  MAJOR_DETAILS: 'Major details',
  FAVORITE_MAJORS: 'Favorite majors',
  SCHOOLS_BY_MAJOR: 'Schools by major',
  EDUCATION_PLANNER: 'Planning dashboard',
  EDUCATION_PLANNER_SCHOOLS: 'College applications',
  SCHOLARSHIPS: 'Scholarships',
  SCHOLARSHIP_DETAILS: 'Scholarship details',
  ADMIN_HOME_PAGE: 'My College',
  ECP_HOME_PAGE: 'Education & Career Plan',
  COLLEGE_APPLICATION_REQUESTS: 'College application requests',
  RECOMMENDER_PROFILE: 'Profile information',
};
