import { Divider } from '@mui/material';

import { Roles } from '@youscience/commonapp-service-common';

import { CoreBox, CoreTooltip, CoreTypography } from '@youscience/khaleesi';

import { useRecommenderProfileStore } from '@store/recommenderProfileStore';
import useCommonAppAdminStore from '@store/CommonAppAdminStore';

import { ROUTES } from '@constants/ROUTES';

import { useRecommenderSpaceSidebar } from '@hooks/useRecommenderSpaceSidebar';

import ChevronLeftIcon from '@images/chevron-left.svg?react';
import SchoolHatIcon from '@images/school-hat.svg?react';
import FileIcon from '@images/file.svg?react';
import WarningIcon from '@images/warning.svg?react';

import {
  sxStyles,
  StyledSidebarWrap,
  StyledToggle,
  StyledSidebarList,
  StyledSidebarRow,
  StyledSidebarItem,
  StyledSectionMenu,
  StyledSectionHeader,
  StyledSectionItem,
  StyledSectionToggle,
} from './RecommenderSpaceSidebar.styles';
import { useEffect, useState } from 'react';

const ECP_APPS_URL = import.meta.env.VITE_ECP_URL;

export const RecommenderSpaceSidebar = () => {
  const { isSidebarOpen, toggleSidebar, isCommonAppSidebarOpen, toggleCommonAppSidebar } = useRecommenderSpaceSidebar();

  const {
    formState: {
      personalDetails: personalDetailsFormState,
      certifications: certificationsFormState,
      schoolDetailsCounselor: schoolDetailsCounselorFormState,
      schoolProfile: schoolProfileFormState,
      classRank: classRankFormState,
      gpa: gpaFormState,
      transcripts: transcriptsFormState,
    },
  } = useRecommenderProfileStore((store) => store);

  const { userRoles } = useCommonAppAdminStore((store) => store);
  const isCounselorRole = userRoles?.includes(Roles.COUNSELOR);

  const [recommenderProfileChangesExist, setRecommenderProfileChangesExist] = useState(false);
  const [recommenderSchoolProfileChangesExist, setRecommenderSchoolProfileChangesExist] = useState(false);

  useEffect(() => {
    setRecommenderProfileChangesExist(
      !location.pathname.endsWith(ROUTES.RECOMMENDER_PROFILE) &&
        (personalDetailsFormState.hasChanged || certificationsFormState.hasChanged),
    );
  }, [location.pathname, personalDetailsFormState.hasChanged, certificationsFormState.hasChanged]);

  useEffect(() => {
    setRecommenderSchoolProfileChangesExist(
      !location.pathname.endsWith(ROUTES.SCHOOL_PROFILE) &&
        (schoolDetailsCounselorFormState.hasChanged ||
          schoolProfileFormState.hasChanged ||
          classRankFormState.hasChanged ||
          gpaFormState.hasChanged ||
          transcriptsFormState.hasChanged),
    );
  }, [
    location.pathname,
    schoolDetailsCounselorFormState.hasChanged,
    schoolProfileFormState.hasChanged,
    classRankFormState.hasChanged,
    gpaFormState.hasChanged,
    transcriptsFormState.hasChanged,
  ]);

  return (
    <StyledSidebarWrap data-testid="recommenderSpaceSidebar" variant="permanent" open={isSidebarOpen}>
      <CoreBox sx={sxStyles.toggleWrap}>
        <StyledToggle isSidebarOpen={isSidebarOpen} onClick={toggleSidebar}>
          <ChevronLeftIcon data-testid="closeIcon" />
        </StyledToggle>
      </CoreBox>

      <StyledSidebarList data-testid="sidebarList" isSidebarOpen={isSidebarOpen}>
        <CoreBox sx={sxStyles.topSidebarActions}>
          {isSidebarOpen ? (
            <CoreTypography sx={sxStyles.sidebarTitle} variant="subtitle4">
              Explore your tools
            </CoreTypography>
          ) : null}

          <StyledSidebarRow to={ECP_APPS_URL} isSelected={false} isSidebarOpen={isSidebarOpen}>
            <SchoolHatIcon />

            {isSidebarOpen ? (
              <CoreBox>
                <CoreTypography variant="body2">Education & Career Plan</CoreTypography>
              </CoreBox>
            ) : null}
          </StyledSidebarRow>

          <StyledSectionMenu>
            <StyledSectionHeader>
              <CoreBox sx={{ display: 'flex', gap: '0.75rem' }}>
                <FileIcon />

                {isSidebarOpen ? (
                  <CoreBox>
                    <CoreTypography variant="body2">Common App request</CoreTypography>
                  </CoreBox>
                ) : null}
              </CoreBox>
              {isSidebarOpen ? (
                <StyledSectionToggle isSidebarOpen={isCommonAppSidebarOpen} onClick={toggleCommonAppSidebar}>
                  <ChevronLeftIcon data-testid="closeIcon" />
                </StyledSectionToggle>
              ) : null}
            </StyledSectionHeader>
            {isCommonAppSidebarOpen ? (
              <>
                <StyledSectionItem
                  to={ROUTES.RECOMMENDER_SPACE}
                  isSelected={location.pathname.endsWith(ROUTES.RECOMMENDER_SPACE)}
                  isSidebarOpen={isSidebarOpen}
                >
                  {isSidebarOpen ? (
                    <CoreBox>
                      <CoreTypography variant="body2">College application requests</CoreTypography>
                    </CoreBox>
                  ) : null}
                </StyledSectionItem>

                <StyledSectionItem
                  to={ROUTES.RECOMMENDER_PROFILE}
                  isSelected={location.pathname.endsWith(ROUTES.RECOMMENDER_PROFILE)}
                  isSidebarOpen={isSidebarOpen}
                >
                  {isSidebarOpen ? (
                    <StyledSidebarItem>
                      <CoreTypography variant="body2">Recommender Profile</CoreTypography>
                      {recommenderProfileChangesExist ? <UnsavedChangesWarningIcon /> : null}
                    </StyledSidebarItem>
                  ) : null}
                </StyledSectionItem>

                {isCounselorRole ? (
                  <StyledSectionItem
                    to={ROUTES.SCHOOL_PROFILE}
                    isSelected={location.pathname.endsWith(ROUTES.SCHOOL_PROFILE)}
                    isSidebarOpen={isSidebarOpen}
                  >
                    {isSidebarOpen ? (
                      <StyledSidebarItem>
                        <CoreTypography variant="body2">School Profile</CoreTypography>
                        {recommenderSchoolProfileChangesExist ? <UnsavedChangesWarningIcon /> : null}
                      </StyledSidebarItem>
                    ) : null}
                  </StyledSectionItem>
                ) : null}
              </>
            ) : null}
          </StyledSectionMenu>
        </CoreBox>

        <Divider />
      </StyledSidebarList>
    </StyledSidebarWrap>
  );
};

const UnsavedChangesWarningIcon = () => {
  return (
    <CoreTooltip title="You have unsaved updates">
      <CoreBox sx={{ display: 'flex', alignItems: 'center' }}>
        <WarningIcon height="15" />
      </CoreBox>
    </CoreTooltip>
  );
};
