/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint no-underscore-dangle: 0 */
import axios, { AxiosError, AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export class Api {
  public constructor(config?: AxiosRequestConfig) {
    this.api = axios.create(config);

    // for the refresh token, need to initialize the authentication api, too
    this.authApi = axios.create({
      withCredentials: true,
      baseURL: `${import.meta.env.VITE_AUTHENTICATION_BASE_URL as string}`,
      headers: {
        common: new AxiosHeaders({ 'Content-Type': 'application/json', Accept: 'application/json' }),
      },
    });

    const RETRY_LIMIT = 2;
    let retries = 0;

    this.api.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      async (error: AxiosError) => {
        const originalConfig = error.config;
        const modifiedConfig = { ...originalConfig, _retry: false };

        retries += 1;
        if (error.response && retries < RETRY_LIMIT) {
          // The access token has expired
          if ((error.response.status === 401 || error.response.status === 403) && !modifiedConfig._retry) {
            modifiedConfig._retry = true;

            try {
              await this.refreshToken();

              return await this.api(originalConfig!);
            } catch (_error) {
              const url = `${import.meta.env.VITE_AUTHENTICATION_BASE_URL as string}?redirectUrl=${
                window.location.protocol
              }//${window.location.host}`;

              window.location.replace(url);
            }
          } else {
            return this.api(originalConfig!);
          }
        }

        return Promise.reject(error);
      },
    );
    this.request = this.request.bind(this);
    this.get = this.get.bind(this);
    this.delete = this.delete.bind(this);
    this.head = this.head.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.patch = this.patch.bind(this);
  }

  public refreshToken = async (): Promise<void> => {
    try {
      await this.authApi.post('/refreshToken');
    } catch (_error) {
      const url = `${import.meta.env.VITE_AUTHENTICATION_BASE_URL as string}?redirectUrl=${window.location.protocol}//${
        window.location.host
      }`;

      window.location.replace(url);
    }
  };

  api: AxiosInstance;

  authApi: AxiosInstance;

  /**
   * Generic request.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP axios response payload.
   * @memberof Api
   *
   * @example
   * api.request({
   *   method: "GET|POST|DELETE|PUT|PATCH"
   *   baseUrl: "http://www.domain.com",
   *   url: "/api/v1/users",
   *   headers: {
   *     "Content-Type": "application/json"
   *  }
   * }).then((response: AxiosResponse<User>) => response.data)
   *
   */
  public request<T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.api.request(config);
  }

  /**
   * HTTP GET method, used to fetch data `statusCode`: 200.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} HTTP `axios` response payload.
   * @memberof Api
   */
  public get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.get(url, config);
  }

  /**
   * HTTP GET method, used to fetch data `statusCode`: 200.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<T>} data property from HTTP `axios` response payload.
   * @memberof Api
   */
  public async getAsync<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return (await this.get(url, config)).data as T;
  }

  /**
   * HTTP DELETE method, `statusCode`: 204 No Content.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public delete<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.delete(url, config);
  }

  /**
   * HTTP DELETE method, `statusCode`: 204 No Content.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - data property from HTTP [axios] response payload.
   * @memberof Api
   */
  public async deleteAsync<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return (await this.delete(url, config)).data as T;
  }

  /**
   * HTTP HEAD method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public head<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.head(url, config);
  }

  /**
   * HTTP POST method `statusCode`: 201 Created.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public post<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
    return this.api.post(url, data, config);
  }

  /**
   * HTTP POST method `statusCode`: 201 Created.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - data property from HTTP [axios] response payload.
   * @memberof Api
   */
  public async postAsync<T, B>(url: string, data?: B, config?: AxiosRequestConfig): Promise<T> {
    return (await this.post(url, data, config)).data as T;
  }

  /**
   * HTTP PUT method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public put<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
    return this.api.put(url, data, config);
  }

  /**
   * HTTP PATCH method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public patch<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
    return this.api.patch(url, data, config);
  }

  /**
   * HTTP PATCH method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - data property of HTTP [axios] response payload.
   * @memberof Api
   */
  public async patchAsync<T, B>(url: string, data?: B, config?: AxiosRequestConfig): Promise<T> {
    return (await this.patch(url, data, config)).data as T;
  }

  /**
   *
   * @template T - type.
   * @param {import("axios").AxiosResponse<T>} response - axios response.
   * @returns {T} - expected object.
   * @memberof Api
   */
  // eslint-disable-next-line
  public success<T>(response: AxiosResponse<T>): T {
    return response.data;
  }

  // eslint-disable-next-line
  public response<T>(response: AxiosResponse<T>): AxiosResponse<T> {
    return response;
  }

  // eslint-disable-next-line
  public error(error: AxiosError<Error>) {
    throw error;
  }
}
