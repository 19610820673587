/* eslint-disable @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-assignment */
import { UserDocument } from '@youscience/user-service-common';
import { AxiosResponse } from 'axios';
import { Api } from './api.service';
import { Subset } from '../../types';

class UserService extends Api {
  constructor() {
    super({
      withCredentials: true,
      baseURL: `${import.meta.env.VITE_GUS_BASE_URL as string}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  public updateUser = (userId: string, userDocument: Subset<UserDocument>): Promise<void | UserDocument> => {
    return this.patch<UserDocument, Subset<UserDocument>, AxiosResponse<UserDocument>>(`/me`, userDocument)
      .then(this.success)
      .catch(this.error);
  };
}

export const userService = new UserService();
