import { Api } from './api.service';

const commonAppUrl = `${import.meta.env.VITE_COMMON_APP_URL}`;

export class CommonAppService extends Api {
  constructor() {
    super({
      withCredentials: true,
      baseURL: commonAppUrl,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }
}

export const commonAppService = new CommonAppService();
