import { useLocation } from 'react-router-dom';
import { CoreTypography, CoreBox } from '@youscience/khaleesi';
import { Divider } from '@mui/material';

import { ROUTES } from '@constants/ROUTES';

import { useRecommenderSpaceSidebar } from '@hooks/useRecommenderSpaceSidebar';

import SchoolHatIcon from '@images/school-hat.svg?react';
import FileIcon from '@images/file.svg?react';
import ChevronBottomIcon from '@images/chevron-bottom.svg?react';

import { sxStyles, StyledSidebarList, StyledSidebarItem, StyledToggle } from './MobileRecommenderSpaceSidebar.styles';

const ECP_APPS_URL = import.meta.env.VITE_ECP_URL;

export const MobileRecommenderSpaceSidebar = () => {
  const location = useLocation();

  const { isSidebarOpen, toggleSidebar } = useRecommenderSpaceSidebar();

  return (
    <StyledSidebarList data-testid="sidebarList">
      {isSidebarOpen ? (
        <>
          <StyledSidebarItem to={ECP_APPS_URL}>
            <SchoolHatIcon />

            <CoreTypography variant="body2">Education & Career Plan</CoreTypography>
          </StyledSidebarItem>

          <StyledSidebarItem to={ROUTES.RECOMMENDER_SPACE} isSelected={location.pathname === ROUTES.RECOMMENDER_SPACE}>
            <FileIcon />

            <CoreTypography variant="body2">College application requests</CoreTypography>
          </StyledSidebarItem>

          <Divider />
        </>
      ) : null}

      <CoreBox sx={sxStyles.toggleWrap} onClick={toggleSidebar}>
        <StyledToggle isSidebarOpen={isSidebarOpen}>
          <ChevronBottomIcon />
        </StyledToggle>
      </CoreBox>
    </StyledSidebarList>
  );
};
